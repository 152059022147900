<template>
    <div id="WS" :style="{ backgroundImage: 'url(' + globalConfig.RESOURCE_PREFIX + '/MBL_E-handbook_Design/2nd_layer/2nd_layer_WS.jpg)' }">
      <a id="forward" href="javascript:void(0);" @click.stop="redirectTo('SystemsDetail','forward')"></a>
      <a id="backward" href="javascript:void(0);" @click.stop="redirectTo('SystemsDetail','backward')"></a>
      <div>
        <span style="
      position: absolute;
      bottom: 0px;
      font-size: 12px;
      left: 3px;
  ">沪ICP备10002431号
      </span>
      <span style="
          bottom: 0px;
          position: absolute;
          right: 3px;
          font-size: 12px;
      ">沪公网安备31010502000766号
      </span>
    </div>
    </div>
</template>
  
  
<script>
  
export default {
  name: "WiperSystems",
  data: () => ({
    loading: false,
    items: [
        { title: 'Click Me' },
        { title: 'Click Me' }
      ],
      offset: true,
  }),
  mounted(){
  },
  computed: {
  },
  methods: {
    redirectTo(path,subSystem) {
      this.$router.push({
        path,
        query: {
          subSysname: subSystem
        },
      });
    },
  },
};
</script>
  
<style lang="sass">
.home
  height: calc(100% - 0px)
  
#WS
  position: relative
  height: 100%
  background-position: 0px 0px !important
  background-size:100%  110%
  max-width: 100% !important
h4
  font-size: 20px
  line-height: 1.25
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif
  color: #005691
  font-style: normal
p
  font-size: 16px !important
  line-height: 1.5 !important
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif !important
#forward
  position: absolute
  left: 16%
  top: calc(31.5% - 0px)
  width: 29%
  height: 4%
  z-index: 999999999 !important
#backward
  position: absolute
  left: 39%
  top: calc(90% - 0px)
  width: 29%
  height: 4%
  z-index: 999999999 !important
</style>